(function($) {
  window.Edu = window.Edu || {};

  // window.Edu.setCheckoutStepInfo = function(data) {
  //   var sku = data.sku;
  //   if(!sku) return;
  //   var name = data.name || sku;
  //   var category = data.category || null;
  //   var price = data.price || 0;
  //   var step_data = step_data || {};
  //
  //   // GA
  //   if(window.ga) {
  //     window.ga('require', 'ec', 'ec.js');
  //     window.ga('ec:addProduct', {
  //       id: sku,
  //       name: name,
  //       category: category,
  //       brand: "Education.com",
  //       price: price,
  //       quantity: 1
  //     });
  //     window.ga('ec:setAction', 'checkout', step_data);
  //   }
  //
  //   // GTM
  //   (window.dataLayer || []).push({
  //     event: 'checkout',
  //     ecommerce: {
  //       checkout: {
  //         actionField: step_data,
  //         products: [{
  //           id: sku,
  //           name: name,
  //           category: category,
  //           brand: "Education.com",
  //           price: price,
  //           quantity: 1
  //         }]
  //       }
  //     }
  //   });
  // };

  window.Edu.cache = {prefix:"educ-"};

  // API helper methods with runtime cache
  window.Edu.api = {
    invalidateStudentsCache: function() {
      if(!window.Edu.memberId) return;
      window.Edu.api._invalidate('api.getStudents.v1.'+window.Edu.memberId);
    },
    getStudents: function(callback, nocache) {
      return new Promise(function(resolve, reject) {
          var cb = function(err, resp) {
              if(callback) {
                  callback(err,resp);
              }

              if(err) reject(Error(err));
              else resolve(resp);
          };

          if(!window.Edu.memberId) {
              cb('No member id');
              return;
          }

          var cache_key = 'api.getStudents.v1.'+window.Edu.memberId;
          var api_process = function(resp) {
              return resp.children;
          };
          window.Edu.api._get(cache_key,'/api/children',{},api_process,cb,nocache);
      });
    },
    invalidateAllGroupsCache: function() {
        if(!window.Edu.memberId) return;
        window.Edu.api._invalidate('api.getAllGroups.v1.'+window.Edu.memberId);
    },
    getAllGroups: function(nocache) {
      return new Promise(function(resolve, reject) {
        var cb = function(err, resp) {
          if(err) reject(Error(err));
          else resolve(resp);
        };

        if(!window.Edu.memberId) {
          cb("No member id");
          return;
        }

        var cache_key = 'api.getAllGroups.v1.'+window.Edu.memberId;
        var api_process = function(resp) {
          return resp.groups;
        };
        window.Edu.api._get(cache_key,'/api/children/groups',{},api_process,cb,nocache);
      });
    },
    invalidateStudentGroupsCache: function() {
      if(!window.Edu.memberId) return;
      window.Edu.api._invalidate('api.getStudentGroups.v1.'+window.Edu.memberId);
    },
    getStudentGroups: function(callback, nocache) {
        return new Promise(function(resolve, reject) {
            var cb = function(err, resp) {
                if(callback) {
                    callback(err,resp);
                }

                if(err) reject(Error(err));
                else resolve(resp);
            };

            if(!window.Edu.memberId) {
                cb('No member id');
                return;
            }

            var cache_key = 'api.getStudentGroups.v1.'+window.Edu.memberId;
            var api_process = function(resp) {
                return resp.groups;
            };
            window.Edu.api._get(cache_key,'/api/children/groups',{},api_process,cb,nocache);
        });
    },
    _invalidate: function(cache_key) {
      if (!cache_key) return;

      // If we're currently fetching, no need to invalidate
      if(window.Edu.cache[cache_key] === 'fetching') {
        return;
      }

      // Invalidate runtime cache
      window.Edu.cache[cache_key] = null;

      // Invalidate local storage cache
      window.localStorage.removeItem(window.Edu.cache.prefix+cache_key);
    },
    _get: function(cache_key, url, data, api_process, callback, nocache) {
      if (cache_key) {
        // Runtime Cache
        if(!nocache && window.Edu.cache[cache_key]) {
            // Another process is currently fetching groups, try again in 200ms
            if(window.Edu.cache[cache_key] === 'fetching') {
                window.setTimeout(function() {
                    window.Edu.api._get(cache_key, url, data, api_process, callback, nocache);
                },200);
                return;
            }

            callback(null, window.Edu.cache[cache_key]);
            return;
        } else {
            window.Edu.cache[cache_key] = 'fetching';
        }
      }

      // Persistent cache disabled via querystring
      if(window.location.href.match(/\bnocache\b/)) {
        nocache = true;
      }

      // Local storage
      if(!nocache) {
        var cached = window.localStorage.getItem(window.Edu.cache.prefix+cache_key);
        if(cached) {
          var decoded = JSON.parse(cached);
          // Make sure cached data hasn't expired yet
          if(decoded && decoded.data && decoded.expires && decoded.expires > (new Date()).getTime()) {
            // Runtime cache
            window.Edu.cache[cache_key] = decoded.data;

            callback(null, window.Edu.cache[cache_key]);
            return;
          }
          else {
            // Either corrupted data or expired, remove from localstorage
            window.localStorage.removeItem(window.Edu.cache.prefix+cache_key);
          }
        }
      }

      // API
      $.get(url, data, function(results) {
        if(results) {
          // Process results into proper format for return
          if(api_process) {
            try {
              results = api_process(results);
            }
            catch(e) {
              callback(e);
              return;
            }
          }

          if (cache_key) {
            // Runtime cache
            window.Edu.cache[cache_key] = results;

            // Local storage cache
            try {
              window.localStorage.setItem(window.Edu.cache.prefix+cache_key, JSON.stringify({
                data: results,
                timeCached: ((new Date()).getTime())/1000,
                // Cache for 20 minutes
                expires: (new Date()).getTime() + 20*60*1000
              }));
            }
            catch(e) {}
          }
          callback(null, results);
        }
        else {
          callback('Failed to load '+cache_key);
        }
      }).fail(function() {
        callback('Failed to load '+cache_key);
      });
    }
  };

  $(document).ajaxSend(function(event, jqXHR, ajaxOptions) {
    if (window.jQuery.inArray(ajaxOptions.type.toLowerCase(), ['post','put','patch','delete']) !== -1 && ajaxOptions.url.substring(0,5).toLowerCase() === '/api/') {
      jqXHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      if (ajaxOptions.data && ajaxOptions.data.length) {
        ajaxOptions.data += '&'+window.jQuery.param({csrfToken: window.Edu.csrfToken});
      } else {
        ajaxOptions.data = window.jQuery.param({csrfToken: window.Edu.csrfToken});
      }
    }
  });

  /** Fix for when the session has expired */
  $(document).ajaxComplete(function(event, xhr, settings) {
    if (!('expiredSessionRefresh' in window.Edu.queryString)) {
      if (xhr && xhr.status === 403 && settings && settings.type === 'POST') {
        if ('expiredSessionRefresh' in xhr.responseJSON) {
          var refreshUrl = window.location.href;
          if (window.Edu.queryString.length) {
              refreshUrl += '&expiredSessionRefresh=true';
          } else {
              refreshUrl += '?expiredSessionRefresh=true';
          }
          window.location = refreshUrl;
        }
      }
    }
  });

  // Action for handling new onboarding
  window.Edu.openOnboarding = function(props) {
    props = props || {};
    $('.new-ppo-confirmation').hide();
    window.Edu.reactDispatch('openOnboardingOverlay', Object.assign(props));
    // @TODO add event tracking here
  };

  window.Edu.queryString = {};
  document.location.search.replace(/(^\?)/, '').split('&').map(function (keyValue) {
    keyValue = keyValue.split('=');
    if (keyValue[0] && keyValue[1]) {
      window.Edu.queryString[keyValue[0]] = keyValue[1];
    }
  });

  var methods = ['trackPurchase', 'trackEvent', 'reactDispatch', 'openLogin', 'openSignup', 'modal', 'lazyload', 'lazyloadall', 'assignment', 'openOnboarding'];
  window.EduQueue = [];
  for(var i=0; i<methods.length; i++) {
    (function(i) {
      window.Edu[methods[i]] = function() {
        window.EduQueue.push([methods[i], arguments]);
      }
    })(i);
  }

  jQuery.fn.modal = function(options) {
    window.Edu.modal(this, options, Array.prototype.slice.call(arguments,1));
    return this;
  };
  jQuery.fn.lazyloadall = function() {
    window.Edu.lazyloadall(this);
    return this;
  };

  /**** Prefix asset urls with the CDN if enabled ****/
  window.Edu.prefixCDN = function(path){
    if(!path) return '';
    if(window.CDNfail) return path;
    var is_absolute = (path.substring(0,7)==='http://' || path.substring(0,8)==='https://');

    // 1. if URL is relative and missing the leading slash, like "assets/something.png", prepend a leading slash
    if ( !is_absolute && path[0] !== '/' ){
      path = '/'+path;
    }

    // 2. check if CDN is enabled. if so:
    //    a. relative paths resolve to cdn+path
    //    b. http(s)://www.education.com+path resolves to cdn+path
    //    c. http(s)://other.domain is not modified
    if(window.Edu.cdn) {
      if (is_absolute) {
        if (path.substring(0, 24) === 'http://www.education.com') {
          path = window.Edu.cdn + path.substring(24);
        } else if (path.substring(0, 25) === 'https://www.education.com') {
          path = window.Edu.cdn + path.substring(25);
        }
      } else {
        path = window.Edu.cdn + path;
      }
    }

    return path;
  };

})(window.jQuery||window.Zepto);
